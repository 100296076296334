<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item>公司资料</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="operation-nav">
        <el-button round type="success" @click="openTips">上首页</el-button>
        <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
      </div>
    </div>
    <div class="content-main">
      <div class="form-table-box">
        <el-form
          ref="infoForm"
          :rules="infoRules"
          :model="infoForm"
          label-width="120px"
        >
          <el-form-item label="公司名称" prop="company">
            <el-input v-model="infoForm.company" :disabled="true"></el-input>
          </el-form-item>

          <el-form-item label="官方网站" prop="website">
            <el-input v-model="infoForm.website"></el-input>
          </el-form-item>

          <el-form-item label="主营业务" prop="main_business">
            <el-input
              v-model="infoForm.main_business"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item class="full-row" label="主营类目">
            <el-select
              multiple
              class="el-select-class"
              v-model="infoForm.cate_ids"
              placeholder="选择类目"
            >
              <el-option
                v-for="item in cateOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="业务联系人" prop="contacts">
            <el-input v-model="infoForm.contacts"></el-input>
          </el-form-item>
          <el-form-item label="业务联系电话" prop="phone">
            <el-input v-model="infoForm.phone"></el-input>
          </el-form-item>

          <el-form-item label="业务联系邮箱" prop="email">
            <el-input v-model="infoForm.email"></el-input>
          </el-form-item>

          <el-form-item label="公司固话" prop="tele">
            <el-input v-model="infoForm.tele"></el-input>
          </el-form-item>

          <el-form-item label="传真" prop="fax">
            <el-input v-model="infoForm.fax"></el-input>
          </el-form-item>

          <el-form-item
            label="业务联系微信"
            prop="wechat"
            v-if="infoForm.wechat"
            class="image-uploader-diy new-height"
          >
            <img
              v-if="infoForm.wechat"
              :src="infoForm.wechat"
              class="image-show"
            />
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="deleWxList"
            >
            </el-button>
            <i class="fa fa-trash-o"></i>
          </el-form-item>

          <el-form-item
            label="微信二维码"
            prop="wechat"
            v-if="!infoForm.wechat"
          >
            <el-upload
              name="file"
              class="upload-demo"
              :action="qiniuZone"
              :on-success="handleUploadWechatSuccess"
              :before-upload="getQiniuToken"
              list-type="picture-card"
              :data="picData"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，压缩后再上传
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item class="full-row" label="生产地址">
            <el-select
              prop="supplier_select"
              filterable
              clearable
              @change.native="selectBlur"
              @blur.native="selectBlur"
              class="supplier_select"
              v-model="infoForm.province_id"
              placeholder="选择省"
              @change="provinceChange"
            >
              <el-option
                v-for="item in provinceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              filterable
              clearable
              class="el-select-class"
              v-model="infoForm.city_id"
              placeholder="选择市"
              @change="cityChange"
              style="margin-left: 20px"
            >
              <el-option
                v-for="item in cityList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-select
              class="el-select-class"
              v-model="infoForm.area_id"
              placeholder="选择区"
              style="margin-left: 20px"
            >
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="" prop="address">
            <el-input
              v-model="infoForm.address"
              style="display: inline-flex; width: 500px"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="公司LOGO"
            prop="avatar"
            v-if="infoForm.avatar"
            class="image-uploader-diy new-height"
          >
            <img
              v-if="infoForm.avatar"
              :src="infoForm.avatar"
              class="image-show"
            />
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="delePicList"
            >
            </el-button>
            <i class="fa fa-trash-o"></i>
          </el-form-item>

          <el-form-item label="公司Logo" prop="avatar" v-if="!infoForm.avatar">
            <el-upload
              name="file"
              class="upload-demo"
              :action="qiniuZone"
              :on-success="handleUploadListSuccess"
              :before-upload="getQiniuToken"
              list-type="picture-card"
              :data="picData"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，压缩后再上传
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="宣传视频" prop="video">
            <div class="pic_img">
              <div class="pic_img_box">
                <el-upload
                  class="avatar-uploader-video"
                  name="file"
                  :action="publicQiniuZone"
                  :on-success="handleVideoSuccess"
                  :before-upload="beforeUploadVideo"
                  list-type="picture-card"
                  :data="picData"
                  v-bind:on-progress="uploadVideoProcess"
                  v-bind:show-file-list="false"
                >
                  <video
                    v-if="videoForm.showVideoPath != '' && !videoFlag"
                    v-bind:src="videoForm.showVideoPath"
                    class="avatar video-avatar"
                    controls="controls"
                  >
                    您的浏览器不支持视频播放
                  </video>
                  <i
                    v-else-if="videoForm.showVideoPath == '' && !videoFlag"
                    class="el-icon-plus avatar-uploader-icon"
                  ></i>
                  <el-progress
                    v-if="videoFlag == true"
                    type="circle"
                    v-bind:percentage="videoUploadPercent"
                    style="margin-top: 7px"
                  ></el-progress>
                </el-upload>
              </div>
            </div>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="deleVideoPath"
            >
            </el-button>
            <i class="fa fa-trash-o"></i>
          </el-form-item>

          <!-- <el-form-item label="宣传图" prop="goods_sn">
            <el-upload
              name="file"
              class="upload-demo"
              :action="qiniuZone"
              list-type="picture-card"
              :on-preview="galleryPreview"
              :on-success="handleUploadGallerySuccess"
              :on-remove="galleryRemove"
              :file-list="gallery_list"
              :data="picData"
              :before-upload="galleryBefore"
              :on-error="hasErrorAct"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-button type="primary" size="small" @click="goodsGalleryEdit"
              >编辑顺序</el-button
            >

            <el-dialog v-model="dialogVisible" size="tiny">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item> -->

          <el-form-item label="公司介绍" prop="desc">
            <div class="edit_container">
              <quill-editor
                v-model="infoForm.desc"
                ref="myTextEditor"
                class="editer"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @ready="onEditorReady($event)"
              >
              </quill-editor>
            </div>
          </el-form-item>
          <!-- 图片上传组件辅助-->
          <el-form-item class="upload_ad">
            <el-upload
              name="file"
              class="avatar-uploader"
              :action="qiniuZone"
              list-type="picture-card"
              :file-list="detail_list"
              :before-upload="beforeUpload"
              :on-success="handleUploadDetailSuccess"
              :on-preview="handlePreview"
              :data="picData"
            >
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" size="tiny">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import api from "@/config/api";
import $ from "jquery";
import { quillEditor } from "vue-quill-editor";
import ElForm from "element-ui/packages/form/src/form.vue";
import ElFormItem from "element-ui/packages/form/src/form-item.vue"; //调用富文本编辑器
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["clean"],
  ["link", "image", "video"],
];
export default {
  name: "CompanyEditPage",
  data() {
    return {
      cityList: [],
      provinceList: [],
      areaList: [],
      root: "",
      qiniuZone: "",
      publicQiniuZone: "",
      picData: {
        token: "",
      },
      url: "",

      cateOptions: [],
      cateId: [],
      detail_list: [],

      infoForm: {
        company: "",
        main_business: "",
        avatar: "",
        video: "",
        desc: "",
        province_id: "",
        city_id: "",
        area_id: "",
        address: "",
        contacts: "",
        phone: "",
        wechat: "",
      },
      infoRules: {},
      gallery: {
        goods_id: 0,
      },
      gallery_list: [],

      dialogImageUrl: "",
      dialogVisible: false,

      uploaderHeader: {
        "I-WanBo-Token": localStorage.getItem("token") || "",
      },

      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                if (value) {
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      videoFlag: false,
      videoUploadPercent: "",
      isShowUploadVideo: false,
      videoForm: {
        showVideoPath: "",
      },
    };
  },
  methods: {
    openTips() {
      this.$alert("请联系您的商务经理帮助您开通上首页", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.axios
            .get("dynamic/addClick", {
              params: {
                event: "goods",
              },
            })
            .then((response) => {});
        },
      });
    },
    getProvinceList() {
      let that = this;
      this.axios
        .get("index/getProvince", {
          params: {},
        })
        .then((response) => {
          let options = response.data.data;
          that.provinceList = options;
        });
    },
    provinceChange() {
      let that = this;
      this.axios
        .get("index/getCity?parent_id=" + that.infoForm.province_id, {
          params: {},
        })
        .then((response) => {
          let options = response.data.data;
          that.cityList = options;
        });
    },
    cityChange() {
      let that = this;
      this.axios
        .get("index/getArea?parent_id=" + that.infoForm.city_id, {
          params: {},
        })
        .then((response) => {
          let options = response.data.data;
          that.areaList = options;
        });
    },

    handleUploadcetificateSuccess(res, file) {
      let url = this.url;
      this.infoForm.certificate = url + res.key;
    },

    selectBlur(e) {
      // this.searchForm.fileExt = e.target.value
    },

    hasErrorAct(err) {
      console.log(err);
    },
    getQiniuToken() {
      let that = this;
      this.axios.post("index/getPublicQiniuToken").then((response) => {
        let resInfo = response.data.data;
        that.picData.token = resInfo.token;
        that.url = resInfo.url;
      });
    },
    //视频上传部分
    beforeUploadVideo(file) {
      var fileSize = file.size / 1024 / 1024 < 50;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
          "video/mov",
        ].indexOf(file.type) == -1
      ) {
        layer.msg("请上传正确的视频格式");
        return false;
      }
      if (!fileSize) {
        layer.msg("视频大小不能超过50MB");
        return false;
      }
      this.isShowUploadVideo = false;
      this.getPublicQiniuToken();
    },
    getPublicQiniuToken() {
      let that = this;
      this.axios.post("index/getPublicQiniuToken").then((response) => {
        let resInfo = response.data.data;
        that.picData.token = resInfo.token;
        that.url = resInfo.url;
      });
    },
    //进度条
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },

    //上传成功回调
    handleVideoSuccess(res, file) {
      this.isShowUploadVideo = true;
      this.videoFlag = false;
      this.videoUploadPercent = 0;

      let url = this.url;
      this.videoForm.showVideoPath = url + res.key;
    },
    deleVideoPath() {
      this.videoForm.showVideoPath = "";
      this.videoFlag = false;

      let id = this.infoForm.id;
      this.axios.post("goods/deleteGoodsVideo", { id: id }).then((response) => {
        if (response.data.errno === 0) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
        } else {
          this.$message({
            type: "error",
            message: "删除失败",
          });
        }
      });
    },

    goodsGalleryEdit() {
      this.$router.push({
        name: "goodsgalleryedit",
        query: { id: this.infoForm.id },
      });
    },

    getImgUrl() {
      let str = this.infoForm.desc;
      //匹配图片（g表示匹配所有结果i表示区分大小写）
      let imgReg = /<img [^>]*src=['"]([^'"]+)[^>]*>/gi;
      //匹配src属性
      let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
      let arr = str.match(imgReg);
      if (arr == null) {
        return false;
      }
      let data = [];

      for (let i = 0; i < arr.length; i++) {
        let src = arr[i].match(srcReg);
        let jsonData = {};
        jsonData.url = src[1];
        data[i] = jsonData;
      }
      this.detail_list = data;
    },
    submitUpload() {
      this.$refs.upload.submit();
    },

    delePicList() {
      this.infoForm.avatar = "";
    },
    deleWxList() {
      this.infoForm.wechat = "";
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    galleryBefore() {
      this.getQiniuToken();
    },
    galleryRemove(file, fileList) {
      let para = {
        id: file.id,
        url: file.url,
      };
      this.axios.post("brand/deleteGalleryFile", para).then((response) => {
        if (response.data.errno === 0) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
        } else {
          this.$message({
            type: "error",
            message: "删除失败",
          });
        }
      });
    },
    galleryPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getGalleryList() {
      this.axios.post("brand/getGalleryList").then((response) => {
        this.gallery_list = response.data.data;
      });
    },

    onEditorReady(editor) {
      console.log("editor ready!", editor);
    },
    onEditorFocus(editor) {
      console.log("editor focus!", editor);
    },
    onEditorBlur(editor) {
      console.log("editor blur!", editor);
    },

    beforeUpload() {
      // 显示loading动画
      this.getQiniuToken();
      this.quillUpdateImg = true;
    },
    uploadError() {
      // loading动画消失
      this.quillUpdateImg = false;
      this.$message.error("图片插入失败");
    },
    //富文本插入网络图片
    onLinkImageUrl() {
      var imageurl = document.querySelector(".url-image-fuzhu input").value;
      let quill = this.$refs.myTextEditor.quill;
      let length = quill.getSelection().index;
      quill.insertEmbed(length, "image", imageurl);
      quill.setSelection(length + 1);
    },

    onSubmitInfo() {
      this.$refs["infoForm"].validate((valid) => {
        if (valid) {
          if (this.videoForm.showVideoPath.length > 0) {
            this.infoForm.video = this.videoForm.showVideoPath;
          }
          const loading = this.$loading({
            lock: true,
            text: "正在保存中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.5)",
          });
          this.infoForm.cate_id = this.infoForm.cate_ids.join(",");
          this.axios
            .post("brand/store", {
              info: this.infoForm,
            })
            .then((response) => {
              loading.close();

              if (response.data.errno === 0) {
                this.$message({
                  type: "success",
                  message: "保存成功",
                });
                // this.$router.go(-1);
              } else {
                this.$message({
                  type: "error",
                  message: "保存失败",
                });
              }
            });
        } else {
          return false;
        }
      });
    },

    handleUploadListSuccess(res) {
      let url = this.url;
      this.infoForm.avatar = url + res.key;
    },
    handleUploadWechatSuccess(res) {
      let url = this.url;
      this.infoForm.wechat = url + res.key;
    },

    handleUploadDetailSuccess(res) {
      let url = this.url;
      let data = url + res.key;
      console.log(data);
      let quill = this.$refs.myTextEditor.quill;
      // 如果上传成功
      // 获取光标所在位置
      let length = quill.getSelection().index;
      // 插入图片  res.info为服务器返回的图片地址
      quill.insertEmbed(length, "image", data);
      // 调整光标到最后
      quill.setSelection(length + 1);
      console.log(quill);
      // this.$message.error('图片插入失败')
      // loading动画消失
      this.quillUpdateImg = false;
    },
    handleUploadGallerySuccess(res) {
      console.log(res);
      let url = this.url;
      if (res.key != "") {
        let urlData = url + res.key;
        let id = this.infoForm.id;
        let info = {
          url: urlData,
          goods_id: id,
        };
        let that = this;
        this.axios.post("goods/gallery", info).then((response) => {
          that.getGalleryList();
        });
      }
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    getInfo() {
      let that = this;
      this.axios.get("brand/info", {}).then((response) => {
        let resInfo = response.data.data;
        let ids = resInfo.cate_id.split(",");
        let idss = [];
        for (let item of ids) {
          let itemint = parseInt(item);
          idss.push(itemint);
        }
        resInfo.cate_ids = idss;
        that.infoForm = resInfo;
        if (resInfo.video && resInfo.video.length > 0) {
          that.videoForm.showVideoPath = resInfo.video;
        }

        this.provinceChange();
        this.cityChange();
        that.getImgUrl();
      });
    },
    // 获取所有分类
    getAllCategory() {
      let that = this;
      this.axios
        .get("brand/getAllCategory", {
          params: {},
        })
        .then((response) => {
          that.cateOptions = response.data.data;
        });
    },

    // summernote 上传图片，返回链接
    sendFile(file) {},
    initSummerNote() {
      let that = this;
      $("#summernote").summernote({
        lang: "zh-CN",
        placeholder: "请输入商品描述",
        height: 300,
        minHeight: 300,
        maxHeight: 400,
        focus: true,

        callbacks: {
          onBlur: function (e) {
            console.log(" on blur ");
            console.log($("#summernote").summernote("code"));
            that.infoForm.desc = $("#summernote").summernote("code");
          },
          onImageUpload: function (files) {
            console.log("onImageUpLoad...");
            that.sendFile(files[0]);
          },
        },
      }),
        // console.error(that.infoForm.desc);
        $("#summernote").summernote("code", that.infoForm.desc);
    },
  },
  components: {
    ElFormItem,
    ElForm,
    quillEditor,
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quillEditor;
    },
  },
  mounted() {
    this.getInfo();
    this.getProvinceList();
    this.getAllCategory();
    this.getQiniuToken();
    this.root = api.rootUrl;
    this.qiniuZone = api.publicQiniu;
    this.publicQiniuZone = api.publicQiniu;
  },
};
</script>

<style lang="scss" scoped>
/* .edit_container{ */
/*.avatar-uploader .el-upload {*/
/*display: none;*/
/*}*/

.video-wrap {
  width: 300px;
}
.dialog-header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.dialog-header .value {
  width: 150px;
  margin-right: 14px;
}

.input-wrap .el-input {
  width: 200px;
  float: left;
  margin: 0 20px 20px 0;
}

.input-wrap .el-input input {
  background-color: #fff !important;
  color: #233445 !important;
}

.specFormDialig .el-input {
  width: 150px;
  margin-right: 10px;
}

.el-select-class {
  width: 200px;
  margin-right: 20px;
}

.sepc-form {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.spec-form-wrap {
  margin-top: 0 !important;
}

.add-spec {
  margin-top: 10px;
}

.spec-form-wrap .header {
  display: flex;
  justify-content: flex-start;
}

.spec-form-wrap .header .l {
  width: 200px;
  margin-right: 20px;
}

.spec-form-wrap .header .m {
  width: 200px;
  margin-right: 20px;
}

.spec-form-wrap .header .m {
  width: 200px;
  margin-right: 20px;
}
.video-avatar {
  width: 148px;
  height: 148px;
}

/*.sepc-form div{*/
/*margin-left: 0;*/
/*}*/

.float-right {
  float: right;
}

.sepc-form .el-input {
  width: 200px;
  margin-right: 20px;
}

.marginTop20 {
  margin-top: 20px;
}

.checkbox-wrap .checkbox-list {
  float: left;
  margin-right: 20px;
}

.upload_ad {
  display: none;
}
.upload_ad .el-upload--picture-card {
  display: none;
}

.ql-container {
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
}

.image-uploader-diy {
  /*height: 200px;*/
  position: relative;
}

/*.dele-list-pic {*/
/*position: absolute;*/
/*left: 380px;*/
/*top: 0px;*/
/*}*/

.image-uploader-diy .el-upload {
  border: 1px solid #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.image-uploader-diy .el-upload:hover {
  border-color: #20a0ff;
}

.image-uploader-diy .image-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  line-height: 200px;
  text-align: center;
}

.image-uploader-diy .image-show {
  min-width: 105px;
  height: 105px;
  display: block;
}

.image-uploader-diy .new-image-uploader {
  font-size: 28px;
  color: #8c939d;
  width: 165px;
  height: 105px;
  line-height: 105px;
  text-align: center;
}

.image-uploader-diy .new-image-uploader .image-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 165px;
  height: 105px;
  line-height: 105px;
  text-align: center;
}

.image-uploader-diy .new-image-uploader .image-show {
  width: 165px;
  height: 105px;
  display: block;
}

.item-url-image-fuzhu .el-input {
  width: 260px;
}

.hidden {
  display: none;
}
.spec-uploader /deep/ .el-upload {
  width: 50px;
  height: 50px;
  position: relative;
}
</style>
